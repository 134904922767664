// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // apiUrl: 'http://api.walkandwin.tn/walkandwin/api/',
  // filesUrl: 'http://api.walkandwin.tn/walkandwin/api/'
  // apiUrl: 'https://api-walkandwin.continuousnet.com/api/',
  // filesUrl: 'https://api-walkandwin.continuousnet.com/',
  apiUrl: ' https://api-symfony.walkandwin.tn/api/',
  filesUrl: ' https://api-symfony.walkandwin.tn/',
};

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAGA4ZxiQwfWH-hr4wGA_NPL6oxSuXoUr8",
  authDomain: "walkandwin-1ae0c.firebaseapp.com",
  projectId: "walkandwin-1ae0c",
  storageBucket: "walkandwin-1ae0c.firebasestorage.app",
  messagingSenderId: "994277866170",
  appId: "1:994277866170:web:07aa009ffc8e42e7ece083",
  measurementId: "G-JQ17KDDRZQ"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
