import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { MessageService, ConfirmationService } from 'primeng/api';
import moment from 'moment';
import { CommonService } from '../../../../services/common/common.service';
import { Cgu, CgusResult } from '../../../interfaces/cgu.interface';
import { CgusDataService } from '../../../services/cgus-data.service';
import { CgusColumnsService } from '../../../services/cgus-columns.service';
import { EditorService } from '../../../../services/editor/editor.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'app-cgu-session-form',
  templateUrl: './cgu-form.component.html',
  styleUrls: ['./cgu-form.component.scss'],
  providers: [MessageService, ConfirmationService, DynamicDialogRef, DynamicDialogConfig],
})
export class CguFormComponent implements OnInit {

  public cgu: Cgu = {};
  public cguForm: FormGroup = new FormGroup({});
  public cgus: Cgu[] = [];
  public isLoading: boolean = false;
  public redirect = true;
  public disableSubmit = false;
  selectedFile: File | null;
  formData = new FormData();
  public Editor = ClassicEditor;

  //imagePath = new FormControl(this.Cgu.imagePath, []);


  constructor(
    private translate: TranslateService,
    public common: CommonService,
    private router: Router,
    private messageService: MessageService,
    private dynamicDialogConfig: DynamicDialogConfig,
    private dynamicDialogRef: DynamicDialogRef,
    private cgusDataService: CgusDataService,
    public cguColumnsService: CgusColumnsService,
    public editorService: EditorService,

  ) {
    this.selectedFile = null; // Initialize it to a default value, such as null
  }

  ngOnInit(): void {
    if (this.dynamicDialogConfig.data && typeof this.dynamicDialogConfig.data.cgu !== 'undefined') {
      this.cgu = this.dynamicDialogConfig.data.cgu;
    }
    if (typeof this.cgu.id === 'undefined' || this.cgu.id === null) {
      this.cgu.id = null;
      this.cgu.createdAt = moment().set({minute: 0, second: 0, millisecond: 0}).format('YYYY-MM-DD\THH:mm:ssZ');
    }
    this.getCgus();
    this.prepareFrom();
  }

  getCgus(): void {
    if (this.dynamicDialogConfig.data && typeof this.dynamicDialogConfig.data.cgu !== 'undefined' && this.dynamicDialogConfig.data.cgu.length > 0) {
      this.cgus = this.dynamicDialogConfig.data.cgu;
      return;
    }
    this.cgusDataService.getCgus().subscribe((response: CgusResult|any) => {
      this.isLoading = false;
      if (response !== null) {
        this.cgus = response.results;
      }
    });
  }

  prepareFrom(): void {
   // this.cgu.creationDate = moment(this.cgu.creationDate).toDate();
    this.cguForm = new FormGroup({
      id: new FormControl(this.cgu.id, []),
    
      content: new FormControl(this.cgu.content, []),
   
    });
  }

  list() {
    this.router.navigate(['/cgus']);
  }

  showErrors() {
      const invalidFields: string[] = [];
      Object.keys(this.cguForm.controls).forEach((field: any) => {
        const control = this.cguForm.get(field);
        if (control !== null) {
          control.markAsTouched({ onlySelf: true });
          if (control.invalid) {
            invalidFields.push(field);
          }
        }
      });
  }

  submitForm(redirect: boolean) {
    this.redirect = redirect;
    if (!this.cguForm.valid) {
      this.showErrors();
    } else {
      this.saveData(redirect);
    }
  }

  saveData(redirect: boolean) {
    this.cgu = this.cguForm.value;
    this.disableSubmit = true;
    this.formData.append('content', this.cguForm ? this.cguForm.value.content : null);


    if (this.cgu.id === null) {
      this.add(redirect, this.formData);
    } else {
      this.update(redirect, this.formData);
    }
    return false;
  }

  uploadPhoto(event: any): void {
    this.selectedFile = event.target.files[0] as File;
    if (this.selectedFile) {
      this.formData.append('picture', this.selectedFile);
    }
  }

  add(redirect: boolean, data:any) {
    this.cgusDataService.addCgu(data).subscribe((data: any) => {
      this.disableSubmit = false;
      if (redirect && typeof this.dynamicDialogConfig.data !== 'undefined') {
        this.dynamicDialogRef.close({refresh: true});
      } else if (redirect) {
        this.list();
      }
    }, (error: any) => {
      this.disableSubmit = false;
      console.warn(error);
    });
  }

  update(redirect: boolean, data:any) {
    this.cgusDataService.updateCgu(data, this.cguForm.value.id).subscribe((data: any) => {
        this.disableSubmit = false;
        if (redirect && typeof this.dynamicDialogConfig.data !== 'undefined') {
          this.dynamicDialogRef.close({refresh: true});
        } else if (redirect) {
          this.list();
        }
      }, (error: any) => {
        this.disableSubmit = false;
        console.warn(error);
    });
  }

}


@Component({
  selector: 'app-cgu-session-form',
  templateUrl: './cgu-form.component.html',
  styleUrls: ['./cgu-form.component.scss'],
  providers: [MessageService, ConfirmationService],
})
export class DialogCguFormComponent extends CguFormComponent {
}

