<div class="animated fadeIn">
    <div class="loading" *ngIf="isLoading">Loading&#8230;</div>
    <div class="d-flex align-items-center">
        <h1>Location</h1>
    </div>
    <div class="d-flex align-items-center" >

        <form class="w-100" pFocusTrap name="dateForm" id="dateForm" novalidate (ngSubmit)="submitForm(true)"
            [formGroup]="dateForm">

            <div class="row">
                <div class="col-4">
                    <div class="input-group mb-3">
                        <input id="month" type="text" name="phoneNumber" required formControlName="phoneNumber" class="form-control" placeholder="phone number" aria-label="month" aria-describedby="basic-addon1">
                      </div>
                </div>
                <div class="col-4">
                    <div class="input-group mb-3">
                        <input id="month" type="date" name="month" required formControlName="month" class="form-control" placeholder="month" aria-label="month" aria-describedby="basic-addon1">
                      </div>
                </div>
                <div class="col-4">
                    <input class="w-25" type="submit"  class="btn btn-primary" value="Afficher" />
                </div>
            </div>
        </form>

        <p-button (click)="downloadFile(dataLocation)"> Export Log</p-button>
    </div>

    
    



</div>
<!--/.fadeIn-->


<div id="map" style="height: 500px;" ></div>
