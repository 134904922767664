import { Component, OnInit } from '@angular/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { MessageService, ConfirmationService } from 'primeng/api';
import { MembersColumnsService , MembersDataService} from '../../../services';
import { Member } from '../../../interfaces';
import { Column } from '../../../interfaces/column.interface';

@Component({
  selector: 'app-member-details',
  templateUrl: './member-details.component.html',
  styleUrls: ['./member-details.component.scss'],
  providers: [MessageService, ConfirmationService, DynamicDialogRef, DynamicDialogConfig],
})
export class MemberDetailsComponent implements OnInit {

  public member: Member = {};
  public columns: Column[] = [];
  public historiques:[] = []
  historicalData = [
    // { id: 1, date: '2024-12-01', steps: 5100, points: 255 },
    // { id: 2, date: '2024-12-02', steps: 6300, points: 315 },
    // { id: 3, date: '2024-12-03', steps: 4800, points: 240 },
    // Ajoutez d'autres données ici
  ];
  constructor(
    private dynamicDialogConfig: DynamicDialogConfig,
    private membersColumnsService: MembersColumnsService,
    public membersDataService: MembersDataService

  ) { }

  ngOnInit(): void {
    if (this.dynamicDialogConfig.data && typeof this.dynamicDialogConfig.data.member !== 'undefined') {
      this.member = this.dynamicDialogConfig.data.member;
      this.getHistoric(this.member.id);

    }
    console.log('member',this.member.id)
    this.columns = this.membersColumnsService.getColumns();
  }


  getHistoric(id:any): void {

    this.membersDataService.getHistoric(id).subscribe((response: any) => {
        this.historicalData = response.success.data;
        this.historiques = response.success.data;
        console.log('historiques',this.historiques)

    }, (error: any) => {;
    });
  }

}
@Component({
  selector: 'app-member-details',
  templateUrl: './member-details.component.html',
  styleUrls: ['./member-details.component.scss'],
  providers: [MessageService, ConfirmationService],
})
export class DialogMemberDetailsComponent extends MemberDetailsComponent {
}

