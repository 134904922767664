import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavigationService } from '../../services/navigation/navigation.service';
import { Column } from '../interfaces/column.interface';
import { Device, Member } from '../interfaces';
import { Notification } from '../interfaces';
import { User } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class CgusColumnsService {

  public cguClasses: Device[] = [];
  public coaches: Notification[] = [];
  public users: User[] = [];
  public members:Member[]=[];

  constructor(
    private translate: TranslateService,
    private navigation: NavigationService,

  ) {
  }

  getColumns(): Column[] {
    return [
      { field: 'content', sortField: 'content', title: this.translate.instant('CONTENT.DESCRIPTION'), show: (this.navigation.getParamValue('cgu.description.showFiled', true) && true), displayInList: true, type: 'textValue'},
      
      { field: 'actions', title: this.translate.instant('CONTENT.ACTIONS'), type: 'static', show: true, displayInList: true, sortField: null},
    ];
  }


}
