import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Cgu, CgusResult } from '../interfaces';
import { AuthService } from '../../services/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class CgusDataService {

  private uri: string = `${environment.filesUrl}termes-of-use`;
  private headers:any ;

  constructor(
    private http: HttpClient
  ) {
    const jsonAuth = localStorage.getItem(AuthService.ATUH_STORAGE_KEY);
    if (jsonAuth) {
      const token = JSON.parse(jsonAuth)?.token;
      if (token) {
        this.headers = new HttpHeaders({
          enctype: 'multipart/form-data',
          authorization: 'bearer ' + token,
        });
      } 
    }
  }

  getCgus(): Observable<CgusResult|any> {
    return this.http.get(this.uri);
  }

  addCgu(data: Cgu): Observable<Cgu|any> {
    return this.http.post(this.uri + '/add', data);
  }

  updateCgu(data: Cgu, id:any): Observable<Cgu|any> {
    return this.http.post(this.uri + '/update'+ `/${id}`, data);
  }


}
