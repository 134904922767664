<p-toast></p-toast>
<div class="container-fluid container-fullw">
  <div class="p-fluid p-grid">
    <div class="p-col-12">
      <div>
        <p-fieldset [toggleable]="true">
          <ng-template pTemplate="header">
            <span *ngIf="cgu.id === null">{{ 'cgu.NEWcgu' | translate }}</span>
            <span *ngIf="cgu.id !== null">{{ 'cgu.EDITcgu' | translate }}</span>
          </ng-template>
          <form pFocusTrap name="cguForm" id="cguForm" novalidate (ngSubmit)="submitForm(true)" [formGroup]="cguForm" >
            <input type="hidden" name="id" id="cguId" formControlName="id" />
          
            <div class="p-fluid p-grid">

                <div class="p-field p-col-12">
                  <label for="content" class="req">{{ 'CONTENT.DESCRIPTION' | translate }}</label>
                  <ckeditor  inputId="description" formControlName="content"  [editor]="Editor" [config]="editorService.config" type="content"  [required]="true" ></ckeditor>
                </div>
                <div *ngIf="cguForm.controls['content'].invalid && (cguForm.controls['content'].dirty || cguForm.controls['content'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="cguForm.controls['content'].errors !== null">
                  <div *ngIf="cguForm.controls['content'].errors.required">
                   {{ 'CONTENT.DESCRIPTION' | translate }}.
                  </div>
                  </ng-container>
                </div>

           
            
            </div><!--/p-fluid p-grid-->
         

            <div class="p-fluid p-grid">
              <div class="p-col-12">
                <button type="button" (click)="submitForm(true)" class="btn btn-primary pull-right" [disabled]="disableSubmit">
                  <i class="fas fa-spinner fa-spin" *ngIf="disableSubmit && redirect"></i>
                  <i class="icon-save" *ngIf="!(disableSubmit && redirect)"></i>
                  <span class="p-ml-1">{{ 'ACTIONS.SAVE' | translate }}</span>
                </button>
              </div>
            </div>
          </form>
        </p-fieldset>
        
      </div>
    </div><!--/p-col-12-->
  </div><!--/p-fluid p-grid-->
  <p-scrollTop target="parent"></p-scrollTop>
</div><!--/container-fluid container-fullw-->
<!-- end: FORM -->

