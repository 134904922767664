import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CacheService } from '../../services/cache/cache.service';
import { PushDevice, PushDevicesResult } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class PushDevicesDataService {

  private uri: string = `${environment.apiUrl}pushDevice`;
  private cacheKey: string = 'pushDevices.cache';

  constructor(
    private http: HttpClient,
    private cache: CacheService,
  ) {

  }

  getPushDevice(id: number): Observable<PushDevice|any> {
    return this.http.get(this.uri + `/${id}`);
  }

  getPushDevices(parameters: {[param: string]: string | string[]}): Observable<PushDevicesResult|any> {
    return this.http.get(this.uri, {params: parameters});
  }

  addPushDevice(data: PushDevice): Observable<PushDevice|any> {
    this.clearCache();
    const cleanData = Object.assign({}, data);
    delete cleanData.id;
    return this.http.post(this.uri, cleanData);
  }

  addPushDevices(data: PushDevice[]): Observable<PushDevice[]|any> {
    this.clearCache();
    return this.http.post(this.uri + `/bulk`, data);
  }

  updatePushDevice(data: PushDevice): Observable<PushDevice|any> {
    this.clearCache();
    const cleanData = Object.assign({}, data);
    delete cleanData.id;
    return this.http.put(this.uri + `/${data.id}`, cleanData);
  }

  updatePushDevices(data: PushDevice[]): Observable<PushDevice[]|any> {
    this.clearCache();
    return this.http.put(this.uri + `/bulk`, data);
  }

  patchPushDevice(data: PushDevice): Observable<PushDevice|any> {
    this.clearCache();
    const cleanData = Object.assign({}, data);
    delete cleanData.id;
    return this.http.patch(this.uri + `/${data.id}`, cleanData);
  }

  patchPushDevices(data: PushDevice[]): Observable<PushDevice[]|any> {
    this.clearCache();
    return this.http.patch(this.uri + `/bulk`, data);
  }

  deletePushDevice(data: PushDevice): Observable<null|any> {
    this.clearCache();
    return this.http.delete(this.uri + `/${data.id}`);
  }

  deletePushDevices(data: PushDevice[]): Observable<null|any> {
    this.clearCache();
    const params: {ids: string} = {ids: ''};
    const ids: string[] = [];
    data.forEach((pushDevice) => {
      ids.push(pushDevice.id + '');
    });
    params.ids = ids.join(',');
    return this.http.delete(this.uri + `/bulk`, {params: params});
  }

  clearCache(): void {
    this.cache.remove(this.cacheKey);
  }


  addPushDeviceAuthorization(token: PushDevice): Observable<PushDevice|any> {
    this.clearCache();
    const cleanData = Object.assign({}, token);
    delete cleanData.id;
    return this.http.post(`${environment.filesUrl}pushdevice/authorize`+ `/${token}`, cleanData);
  }

  getPushDeviceByID(id: number): Observable<PushDevice|any> {
    return this.http.get(`${environment.filesUrl}pushdevice/authorize` + `/${id}`);
  }


  getPushDevicesUser(id:number ,parameters: {[param: string]: string | string[]}): Observable<PushDevicesResult|any> {
    return this.http.get(`${environment.filesUrl}pushDevice-user`+ `/${id}`, {params: parameters});
  }

}
