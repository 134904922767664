
<div class="animated fadeIn">
  <div class="d-flex align-items-center">
      <h1>{{ 'MEMBER.MEMBERDETAILS' | translate }}</h1>
  </div>
  <p-toast></p-toast>

  <p-card *ngIf="member.id">
    <div class="p-grid">
      <div class="p-col-12" *ngFor="let col of columns">
        <div class="p-grid">
          <div class="p-col-4 text-primary">
            {{ col.title | translate }}
          </div>
          <div class="p-col-8">
            <app-table-cell-value [value]="member[col.field]" [column]="col"></app-table-cell-value>
          </div>
        </div>
      </div>
    </div>

  </p-card>
  <p-table [value]="historicalData" [paginator]="true" [rows]="10" >
    <ng-template pTemplate="header">
      <tr>
        <th>date</th>
            <th>duration</th>
            <th>valid Steps</th>
            <th>valid Distance</th>
            <th>daily Number Of Points</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData>
      <tr>
        <td>{{ rowData.date }}</td>
        <td>{{ rowData.duration }}</td>
        <td>{{ rowData.validSteps }}</td>
        <td>{{ rowData.validDistance }}</td>
        <td>{{ rowData.dailyAchievedNumberOfPoints }}</td>
      </tr>
    </ng-template>
  </p-table>
  

</div>

