
<div class="animated fadeIn">
  <div class="d-flex align-items-center">
      <h1>{{ 'PUSHDEVICEDETAILS' | translate }}</h1>
      <div [hidden]="!isLoading" class="spinner-border text-info  ml-auto" role="status">
        <span class="sr-only">{{ 'LOADING' | translate }}...</span>
      </div>
  </div>
  <p-toast></p-toast>
  <h1 class="mb-4">Gérer l'autorisation de l'appareil</h1>
  <p><strong>Nom de l'appareil :</strong> {{ pushDeviceAut.deviceName }}</p>
  <p><strong>Modèle :</strong> {{ pushDeviceAut.deviceModel }}</p>
  <p><strong>ID :</strong> {{ pushDeviceAut.deviceUid }}</p>
  <div class="d-flex gap-2 mt-3">
    <button class="btn btn-success" (click)="onAction('accept')">Accepter</button>
    <button class="btn btn-danger" (click)="onAction('reject')">Refuser</button>
  </div>

  <p-toast position="bottom-center" key="pushDeviceDelete" (onClose)="onDeleteReject()" [baseZIndex]="5000">
    <ng-template let-message pTemplate="message">
      <div class="p-flex p-flex-column" style="flex: 1">
        <div class="text-center">
          <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
          <h4>{{message.summary}}</h4>
          <p>{{message.detail}}</p>
        </div>
        <div class="row">
          <button type="button" pButton (click)="onDeleteConfirm()" label="Yes" class="col-5 p-button-success"></button>
          <div class="col-2"></div>
          <button type="button" pButton (click)="onDeleteReject()" label="No" class="col-5 p-button-secondary"></button>
        </div>
      </div>
    </ng-template>
  </p-toast>



  <p-table 
  [value]="pushDevices" 
  styleClass="p-datatable-gridlines" 
  [tableStyle]="{ 'min-width': '50rem' }">
      <ng-template pTemplate="header">
          <tr>
              <th>app Version</th>
              <th>device Name</th>
              <th>device Model</th>
              <th>device Uid</th>
              <th>Authorization</th>

              <th>created At</th>
          </tr>
      </ng-template>
      <ng-template pTemplate="body" let-pushDevice>
        <tr [ngClass]="{'highlight-row': pushDeviceAut.deviceUid === pushDevice.deviceUid}">

          <td>{{ pushDevice.appVersion }}</td>
              <td>{{ pushDevice.deviceName }}</td>
              <td>{{ pushDevice.deviceModel }}</td>
              <td>{{ pushDevice.deviceUid }}</td>
              <td>{{ pushDevice.isAuthorized }}</td>
              <td>{{ pushDevice.createdAt.date }}</td>

              


          </tr>
      </ng-template>
</p-table>
 

</div>

