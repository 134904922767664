import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { MessageService, ConfirmationService } from 'primeng/api';
import { Column } from '../../../interfaces/column.interface';
import { CgusColumnsService } from '../../../services/cgus-columns.service';
import { Cgu } from '../../../interfaces/cgu.interface';

@Component({
  selector: 'app-cgu-session-details',
  templateUrl: './cgu-details.component.html',
  styleUrls: ['./cgu-details.component.scss'],
  providers: [MessageService, ConfirmationService, DynamicDialogRef, DynamicDialogConfig],
})
export class CguDetailsComponent implements OnInit, OnDestroy {

  public cgu: Cgu = {};
  public columns: Column[] = [];

  constructor(
    private router: Router,
    private dynamicDialogConfig: DynamicDialogConfig,
    private cgusColumnsService: CgusColumnsService,

  ) { }

  ngOnInit(): void {
    if (this.dynamicDialogConfig.data && typeof this.dynamicDialogConfig.data.cgu !== 'undefined') {
      this.cgu = this.dynamicDialogConfig.data.cgu;
    }
    this.columns = this.cgusColumnsService.getColumns();
    for (let i = 0; i <= this.columns.length; i++) {
      if (this.columns[i].field === "actions") {
        this.columns.splice(i, 1);
      }
    }
  }

  ngOnDestroy(): void {
  }

  list() {
    this.router.navigate(['/cgus']);
  }

  add() {
    this.router.navigate(['/cgus/add']);
  }

  edit() {
    this.router.navigate(['/cgus/edit/' + this.cgu.id]);
  }

}
@Component({
  selector: 'app-cgu-session-details',
  templateUrl: './cgu-details.component.html',
  styleUrls: ['./cgu-details.component.scss'],
  providers: [MessageService, ConfirmationService],
})
export class DialogCguDetailsComponent extends CguDetailsComponent {
}

