import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DialogService, DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { MessageService, ConfirmationService, PrimeNGConfig } from 'primeng/api';
import { Title } from '@angular/platform-browser';
import { NavigationService } from '../../../../services/navigation/navigation.service';
import { CommonService } from '../../../../services/common/common.service';
import { PushDevicesDataService, PushDevicesColumnsService } from '../../../services';
import { PushDevice, PushDevicesResult } from '../../../interfaces';
import { Column } from '../../../interfaces/column.interface';

@Component({
  selector: 'app-push-device-authorization',
  templateUrl: './push-device-authorization.component.html',
  styleUrls: ['./push-device-authorization.component.scss'],
  providers: [MessageService, ConfirmationService, DynamicDialogRef, DynamicDialogConfig],
})
export class PushDeviceAuthorizationComponent implements OnInit, OnDestroy {

  public pushDevice: PushDevice = {};
  public pushDevices: PushDevice[] = [];
  public defaultParameters: {[param: string]: string | string[]} = {offset: '0', limit: '1000'};
  public parameters: {[param: string]: string | string[]} = {offset: '0', limit: '1000'};
  public isLoading: boolean = false;
  public locale: string = (localStorage.language)?localStorage.language:'en';
  public columns: Column[] = [];
  public userId:any;
  public token:any;
  public pushDeviceToAuthorize: PushDevice = {};
  public deviceId :any;
  public pushDeviceAut: PushDevice = {};

  private _selectedColumns: Column[] = [];

  public selectedPushDevices: PushDevice[] = [];
  public submitted: boolean = false;
  constructor(
    private translate: TranslateService,
    private common: CommonService,
    private router: Router,
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private dynamicDialogConfig: DynamicDialogConfig,
    private primengConfig: PrimeNGConfig,
    private titleService: Title,
    private pushDevicesDataService: PushDevicesDataService,
    private pushDevicesColumnsService: PushDevicesColumnsService,

  ) { }

  ngOnInit(): void {
    this.userId = this.route.snapshot.queryParamMap.get('user');
    this.token = this.route.snapshot.queryParamMap.get('token');
    this.deviceId = this.route.snapshot.queryParamMap.get('device');
    this.getPushDevice(this.deviceId);
    console.log('this.userId', this.userId, this.token, this.deviceId)
   
    this.columns = this.pushDevicesColumnsService.getColumns();
    this.getPushDevices();
  }

  getPushDevice(id: number): void {
    this.isLoading = true;

    this.pushDevicesDataService.getPushDeviceByID(id).subscribe((response: PushDevice|any) => {
      this.pushDeviceAut = response.data[0];

      this.isLoading = false;
      if (response !== null) {
        this.pushDevice = response.data[0];
      }
    });
  }
  ngOnDestroy(): void {
  }
  onAction(action: 'accept' | 'reject'): void {
    console.log(action);
    if(action === 'accept') {
      console.log('yes');

      this.pushDeviceDelete(this.pushDevice)
  
    }
  }
 
  pushDeviceDelete(pushDevice: PushDevice): void {
    this.pushDeviceToAuthorize = pushDevice;
    this.messageService.clear();
    this.messageService.add({
      key: 'pushDeviceDelete',
      sticky: true,
      severity: 'warn',
      summary: 'Are you sure you want to authorized the selected Device? ' + this.pushDevice.deviceName + ' ?',
      detail: 'Confirm to proceed'
    });
  }

  // onDeleteConfirm(): void {
  //   console.log('onDeleteConfirm','response');

  //   this.confirmationService.confirm({
  //     message: 'Are you sure you want to authorized the selected Push Devices?',
  //     header: 'Confirm',
  //     icon: 'pi pi-exclamation-triangle',
  //     accept: () => {

  //       this.pushDevicesDataService.addPushDeviceAuthorization(this.token).subscribe((response) => {
  //         console.log(response,'response');

  //         if (response === null) {
  //           this.messageService.clear();
  //           this.messageService.add({severity:'success', summary: 'Successful', detail: 'Push Devices authorized', life: 3000});
  //         } else {
  //           this.messageService.clear();
  //           this.messageService.add({severity: 'error', summary: 'Error', detail: 'Push Devices not authorized'});
  //         }
  //       }, (error: any) => {
  //         this.messageService.clear();
  //         this.messageService.add({severity: 'error', summary: 'Error', detail: 'Push Devices not authorized'});
  //       });
  //     }
  //   });
  // }

  onDeleteReject(): void {
    this.messageService.clear('pushDeviceDelete');
  }

  giftDelete(pushDevice: PushDevice): void {
    this.messageService.clear();
    this.messageService.add({
      key: 'giftDelete',
      sticky: true,
      severity: 'warn',
      summary: 'Are you sure you want to authorized the selected Push Devices?',
      detail: 'Confirm to proceed'
    });
  }

  onDeleteConfirm(): void {
    this.pushDevicesDataService.addPushDeviceAuthorization(this.token).subscribe((response) => {
      console.log(response,'response');

   
        this.messageService.clear();
        this.messageService.add({severity:'success', summary: 'Successful', detail: 'Push Devices authorized', life: 3000});
      
    }, (error: any) => {
      this.messageService.clear();
      this.messageService.add({severity: 'error', summary: 'Error', detail: 'Push Devices not authorized'});
    });
  }

  
  authorizeSelectedPushDevice() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to authorized the selected Push Devices?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.pushDevicesDataService.addPushDeviceAuthorization(this.token).subscribe((response) => {
          console.log(response,'response');

          if (response === null) {
            this.messageService.clear();
            this.messageService.add({severity:'success', summary: 'Successful', detail: 'Push Devices authorized', life: 3000});
          } else {
            this.messageService.clear();
            this.messageService.add({severity: 'error', summary: 'Error', detail: 'Push Devices not authorized'});
          }
        }, (error: any) => {
          this.messageService.clear();
          this.messageService.add({severity: 'error', summary: 'Error', detail: 'Push Devices not authorized'});
        });
      }
    });

  }


  list() {
    this.router.navigate(['/push-devices']);
  }
  getPushDevices(): void {
 
   
    this.pushDevicesDataService.getPushDevicesUser(this.userId ,this.parameters).subscribe((response: PushDevicesResult|any) => {
      this.isLoading = false;
      if (response !== null) {
        this.setColumns();

          this.pushDevices = response.success.data;
          console.log('this.pushDevices', this.pushDevices)
      
       
      }
    }, (error: any) => {;
      this.isLoading = false;
      this.messageService.add({severity: 'error', summary: this.translate.instant('ERROR'), detail: this.translate.instant('PUSHDEVICESNOTLOADED')});
    });
  }
  setColumns() {
    if (this.columns.length > 0) {
      return;
    }
    this.columns = this.pushDevicesColumnsService.getColumns();
    console.log('col', this.columns)

    for (let i = 0; i < this.columns.length; i++) {
      if (this.columns[i].show) {
        this._selectedColumns.push(this.columns[i]);
      }
    }
  }
  @Input()
  get selectedColumns(): any[] {
      return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
      // restore original order
      this._selectedColumns = this.columns.filter((col: any) => val.includes(col));
      this.updateColumns();
  }
  updateColumns() {
    for (let i = 0; i < this.columns.length; i++) {
      const show = this._selectedColumns.includes(this.columns[i]);
      this.columns[i].show = show;
      // this.navigation.setParamValue('pushDevices.' + this.columns[i].field + '.showFiled', show);
    }
  }
}
@Component({
  selector: 'app-push-device-authorization',
  templateUrl: './push-device-authorization.component.html',
  styleUrls: ['./push-device-authorization.component.scss'],
  providers: [MessageService, ConfirmationService],
})
export class DialogPushDeviceAuthorizationComponent extends PushDeviceAuthorizationComponent {
}

